import { Pipe, PipeTransform } from '@angular/core';
import { AddonApartment } from 'projects/apex/src/app/models/addon-apartment';

@Pipe({
  name: 'selectedAddonsComments',
  standalone: true,
})
export class SelectedAddonsCommentsPipe implements PipeTransform {
  transform(selectedAddonApartments: AddonApartment[], comments: string[], addonId: number): string {
    const index = selectedAddonApartments?.findIndex((apartment) => apartment.AddonId === addonId);

    return index !== -1 ? comments[index] : '';
  }
}
