import { Pipe, PipeTransform } from '@angular/core';
import { AddonApartment } from '../../../../../../models/addon-apartment';

@Pipe({
  name: 'selectedAddons',
  standalone: true,
})
export class SelectedAddonsPipe implements PipeTransform {
  transform(selectedAddonApartments: AddonApartment[], addonId: number): boolean {
    return selectedAddonApartments?.some((apartment) => apartment?.AddonId === addonId) || false;
  }
}
