import { Location } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { ClientPageService } from 'projects/apex/src/app/features/client-page/client-page.service';
import { AddonApartment } from 'projects/apex/src/app/models/addon-apartment';
import { AddonCart, Product } from 'projects/apex/src/app/models/addon-cart';
import { AddonCategory } from 'projects/apex/src/app/models/addon-category';
import { Apartment } from 'projects/apex/src/app/models/apartment';
import { FileUsage } from 'projects/apex/src/app/models/file-usage';
import { scrollWrap } from 'projects/apex/src/app/utils/functions';
import { Observable, Subscription, noop } from 'rxjs';
import { take } from 'rxjs/operators';
import { sortAddonCategories } from '../../../../addon/functions';
import { ClientAddonAlertDialogComponent } from '../../components/addon-apartment-alert-dialog/alert-dialog.component';
import { CategoryOrAddon, CustomCategory } from './addons.types';

@Component({
  selector: 'apex-client-addon-addons-page',
  templateUrl: './addons.component.html',
  styleUrls: ['./addons.component.scss'],
})
export class ClientAddonPageComponent implements OnInit, AfterViewInit, OnDestroy {
  cart: AddonCart;
  apartment: Apartment;
  addonCategories: AddonCategory[];
  parentAddonCategories: AddonCategory[];

  viewAddonCategories: CustomCategory[] = [];

  projectText: { content: string; title: string };

  totalPrice = 0;

  tocOpen = false;
  search: string;

  CategoriesAndAddons: CategoryOrAddon[] = [];
  filteredCategoriesAndAddons: CategoryOrAddon[] = [];

  categoryInView = {};

  addonManager: boolean;

  bannerFileUsages$: Observable<FileUsage[]>;

  private subscriptions: Subscription[] = [];

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private clientService: ClientPageService,
    private location: Location,
  ) {}

  ngOnInit(): void {
    scrollWrap(0, 0);

    const data = this.route.snapshot.data;

    this.addonManager = data.addonManager;
    this.apartment = data.apartment;
    this.addonCategories = data.addonCategories;
    this.cart = data.cart;
    this.parentAddonCategories = this.addonCategories.filter((ac) => !ac.ParentId);
    this.checkForAlerts();

    this.projectText = data.projectText;

    this.viewAddonCategories = this.sortCategories();
    this.CategoriesAndAddons = this.buildCategoryOrAddonView();
    this.filteredCategoriesAndAddons = [...this.CategoriesAndAddons];

    this.subscriptions.push(
      this.clientService.categoryInView.subscribe({
        next: ({ inView, id }) => {
          this.categoryInView[id] = inView;
        },
      }),
    );

    const projectId = this.addonCategories?.[0]?.AddonCategoryProjects?.[0]?.ProjectId ?? null;

    if (projectId) {
      this.bannerFileUsages$ = this.clientService.queryFileUsage('addon-project', projectId, 'banner-images');
    }
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(
      this.route.fragment?.subscribe({
        next: (f) => {
          document.getElementById(f)?.scrollIntoView();
        },
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => {
      if (s) {
        s.unsubscribe();
      }
    });
  }

  checkForAlerts(): void {
    if (this.cart) {
      const expiredAddons: Product[] = [];
      const addonsExpireToday: Product[] = [];
      const addonsExpireTomorrow: Product[] = [];
      const updatedAddons: Product[] = this.cart.changedProducts || [];
      const today = moment(new Date());

      if (this.cart.Product) {
        this.cart.Product.forEach((product) => {
          if (!product?.AddonApartment?.dateEnd) {
            return;
          }

          const dateEnd = moment(new Date(product.AddonApartment.dateEnd));
          const dateDiff = dateEnd.diff(today.startOf('day'), 'days');

          if (dateDiff === 0) {
            addonsExpireToday.push(product);
          } else if (dateDiff === 1) {
            addonsExpireTomorrow.push(product);
          } else if (dateDiff < 0) {
            expiredAddons.push(product);
          }
        });
      }

      if (addonsExpireToday.length || addonsExpireTomorrow.length || expiredAddons.length || updatedAddons.length) {
        expiredAddons.length
          ? expiredAddons.forEach((expiredAddon) => {
              this.remove(expiredAddon.AddonApartment);
            })
          : noop();

        this.dialog.open(ClientAddonAlertDialogComponent, {
          data: {
            expiredAddons,
            addonsExpireToday,
            addonsExpireTomorrow,
            updatedAddons,
          },
          disableClose: true,
        });
      }
    }
  }

  add(addObject: { addonApartment: AddonApartment; comment: string }): void {
    const addonCategoryId = addObject.addonApartment.Addon.AddonCategory.id;
    const addonCategory = this.addonCategories.find((category) => category.id === addonCategoryId);

    if (!addonCategory) {
      return;
    }

    if (!addonCategory.AddonCategoryProjects?.some((acp) => acp.allowMultipleAddons)) {
      const existingAddon = this.cart.Product?.some((product) => product.Addon.AddonCategoryId === addonCategoryId);

      if (existingAddon) {
        return;
      }
    }

    const sub = this.clientService
      .addProductInCart(addObject.addonApartment.ApartmentId, addObject.addonApartment.AddonId, addObject.comment)
      .pipe(take(1))
      .subscribe((cart: AddonCart) => {
        this.cart = cart;
      });

    this.subscriptions.push(sub);
  }

  remove(addonApartment: AddonApartment): void {
    const sub = this.clientService
      .removeProductInCart(addonApartment.ApartmentId, addonApartment.AddonId)
      .pipe(take(1))
      .subscribe((cart: AddonCart) => {
        this.cart = cart;
      });

    this.subscriptions.push(sub);
  }

  goToAddon(addonApartment: AddonApartment): void {
    if (addonApartment?.id) {
      void this.router.navigate([addonApartment.id], { relativeTo: this.route });
    }
  }

  back(): void {
    this.location.back();
  }

  sortCategories(): CustomCategory[] {
    const temp: CustomCategory[] = [];

    const topParents = sortAddonCategories([...this.parentAddonCategories]);

    topParents.forEach((ac) => {
      (ac as CustomCategory).level = 0;

      temp.push(ac, ...this.addChildren(ac));
    });

    return temp;
  }

  addChildren(parentCategory: CustomCategory): CustomCategory[] {
    const temp: CustomCategory[] = [];

    let children = this.addonCategories.filter((ac) => ac.ParentId === parentCategory.id);

    if (children?.length) {
      children = sortAddonCategories(children);

      children.forEach((ac) => {
        (ac as CustomCategory).level = parentCategory.level + 1;

        temp.push(ac, ...this.addChildren(ac));
      });
    }

    return temp;
  }

  buildCategoryOrAddonView(): CategoryOrAddon[] {
    const temp: CategoryOrAddon[] = [];

    this.viewAddonCategories.forEach((vac) => {
      temp.push({
        category: vac,
      });

      vac.Addons?.forEach((a) => {
        temp.push({
          addon: a,
        });
      });
    });

    return temp;
  }
}
