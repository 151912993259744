<ng-container *ngIf="profile$ | async as profile">
  <form
    class="profile-form"
    #userForm="ngForm"
    (submit)="save()">
    <apex-box
      class="am"
      expanded>
      <T
        title
        str="Personal Information"></T>

      <T
        description
        str="Basic information about your account"></T>

      <div class="df p-c">
        <mat-form-field class="f1 ams">
          <mat-label>
            <T str="Name"></T>
          </mat-label>
          <input
            matInput
            required
            minlength="2"
            name="name"
            type="text"
            [(ngModel)]="profile.name" />
        </mat-form-field>
        <mat-form-field class="f1 ams">
          <mat-label>
            <T str="Language"></T>
          </mat-label>
          <mat-select
            name="locale"
            [(ngModel)]="profile.locale">
            <mat-option
              [value]="language.code"
              *ngFor="let language of languages$ | async">
              {{ language.localized_name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="df p-c">
        <mat-form-field class="f1 ams">
          <mat-label>
            <T str="Email"></T>
          </mat-label>
          <input
            matInput
            required
            minlength="2"
            name="mail"
            type="email"
            [(ngModel)]="profile.mail" />
        </mat-form-field>
        <mat-form-field class="f1 ams">
          <mat-label>
            <T str="Mobile"></T>
          </mat-label>
          <input
            matInput
            name="mobile"
            type="text"
            [(ngModel)]="profile.mobile" />
        </mat-form-field>
      </div>
      <div class="df w">
        <div class="df f1">
          <mat-form-field class="f1 ams">
            <mat-label>
              <T str="Address"></T>
            </mat-label>
            <input
              matInput
              name="address"
              type="text"
              [(ngModel)]="profile.address" />
          </mat-form-field>
        </div>

        <div class="df f1 p-c">
          <mat-form-field class="f1 ams">
            <mat-label>
              <T str="Zip"></T>
            </mat-label>
            <input
              matInput
              apexPostalOutput
              (postalChange)="profile.city = $event"
              type="text"
              name="zip"
              [(ngModel)]="profile.zip" />
          </mat-form-field>

          <mat-form-field class="f1 ams">
            <mat-label>
              <T str="Postal"></T>
            </mat-label>
            <input
              matInput
              type="text"
              name="postal"
              [(ngModel)]="profile.city" />
          </mat-form-field>
        </div>
      </div>
    </apex-box>

    <apex-box
      class="am"
      *ngIf="!hideSettings">
      <T
        title
        str="HSE Card"></T>

      <T
        description
        str="Information about your HSE Card"></T>

      <div class="df w">
        <div class="df f1">
          <mat-form-field class="f1 ams">
            <mat-label>
              <T str="Card number"></T>
            </mat-label>
            <input
              matInput
              type="text"
              name="hmscard"
              [(ngModel)]="profile.hmscard" />
          </mat-form-field>
        </div>

        <div class="df f1">
          <mat-form-field class="f1 ams">
            <mat-label>
              <T str="Card expires"></T>
            </mat-label>
            <input
              matInput
              [matDatepicker]="expPicker"
              name="hmscardExpire"
              [(ngModel)]="profile.hmscardExpire" />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="expPicker"></mat-datepicker-toggle>
            <mat-datepicker #expPicker></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="df f1">
          <mat-form-field class="f1 ams">
            <mat-label>
              <T str="Date of birth"></T>
            </mat-label>
            <input
              matInput
              [matDatepicker]="birthPicker"
              name="dateOfBirth"
              [(ngModel)]="profile.dateOfBirth" />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="birthPicker"></mat-datepicker-toggle>
            <mat-datepicker #birthPicker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </apex-box>

    <div class="am">
      <apex-addresses
        addressModel="User"
        [modelId]="profile?.id.toString()"
        [allowEdit]="true">
      </apex-addresses>
    </div>

    <apex-box class="am">
      <T
        title
        str="Settings for email"></T>

      <T
        description
        str="Personalize which events APEX should send email"></T>

      <div class="ams display-grid-cards">
        <div class="df c amvs checkbox-wrapper">
          <mat-checkbox
            name="mailNewCase"
            [(ngModel)]="profile.mailNewCase">
            <T str="Send email about new cases"></T>
          </mat-checkbox>
          <div class="mat-caption">
            <T str="Ex. when a new case is assigned to you"></T>
          </div>
        </div>

        <div class="df c amvs checkbox-wrapper">
          <mat-checkbox
            name="mailNewMessage"
            [(ngModel)]="profile.mailNewMessage">
            <T str="Send email about new messages on your cases"></T>
          </mat-checkbox>
          <div class="mat-caption">
            <T str="Ex. when a case has a new message"></T>
          </div>
        </div>

        <div class="df c amvs checkbox-wrapper">
          <mat-checkbox
            name="mailChecklist"
            [(ngModel)]="profile.mailChecklist">
            <T str="Send email about checklists your are part of"></T>
          </mat-checkbox>
          <div class="mat-caption">
            <T str="Ex. when you get access to a checklist or a checklist is completed"></T>
          </div>
        </div>

        <div class="df c amvs checkbox-wrapper">
          <mat-checkbox
            name="mailNewRequest"
            [(ngModel)]="profile.mailNewRequest">
            <T str="Send email about new requests"></T>
          </mat-checkbox>
          <div class="mat-caption">
            <T str="Ex. when a request is created on a project you are part of"></T>
          </div>
        </div>
      </div>
    </apex-box>

    <apex-box
      class="am"
      *ngIf="!hideSettings">
      <T
        title
        str="Settings for unread"></T>

      <T
        description
        str="Personalize when APEX should mark cases unread for you"></T>

      <div class="ams display-grid-cards">
        <div class="df c amvs checkbox-wrapper">
          <mat-checkbox
            name="systemMessage"
            [(ngModel)]="profile.UserPreferencesUnread.systemMessage">
            <T str="System message"></T>
          </mat-checkbox>
          <div class="mat-caption">
            <T str="Ex. when a messages is generated by APEX"></T>
          </div>
        </div>

        <div class="df c amvs checkbox-wrapper">
          <mat-checkbox
            name="caseManagerMessage"
            [(ngModel)]="profile.UserPreferencesUnread.caseManagerMessage">
            <T str="Case manager message"></T>
          </mat-checkbox>
          <div class="mat-caption">
            <T str="Ex. when a case manager adds a message to a case"></T>
          </div>
        </div>

        <div class="df c amvs checkbox-wrapper">
          <mat-checkbox
            name="contractorMessage"
            [(ngModel)]="profile.UserPreferencesUnread.contractorMessage">
            <T str="Contractor message"></T>
          </mat-checkbox>
          <div class="mat-caption">
            <T str="Ex. when a contractor adds a message to a case"></T>
          </div>
        </div>

        <div class="df c amvs checkbox-wrapper">
          <mat-checkbox
            name="clientMessage"
            [(ngModel)]="profile.UserPreferencesUnread.clientMessage">
            <T str="Client message"></T>
          </mat-checkbox>
          <div class="mat-caption">
            <T str="Ex. when a client adds a message to a case"></T>
          </div>
        </div>

        <div class="df c amvs checkbox-wrapper">
          <mat-checkbox
            name="statusChangeAccepted"
            [(ngModel)]="profile.UserPreferencesUnread.statusChangeAccepted">
            <T str="Status changed to Accepted"></T>
          </mat-checkbox>
          <div class="mat-caption">
            <T str="Ex. when a case changes to accepted or a contractor accepts a case"></T>
          </div>
        </div>

        <div class="df c amvs checkbox-wrapper">
          <mat-checkbox
            name="statusChangeDenied"
            [(ngModel)]="profile.UserPreferencesUnread.statusChangeDenied">
            <T str="Status changed to Denied"></T>
          </mat-checkbox>
          <div class="mat-caption">
            <T str="Ex. when a case changes to denied or a contractor denies a case"></T>
          </div>
        </div>

        <div class="df c amvs checkbox-wrapper">
          <mat-checkbox
            name="statusChangeCompleted"
            [(ngModel)]="profile.UserPreferencesUnread.statusChangeCompleted">
            <T str="Status changed to Completed"></T>
          </mat-checkbox>
          <div class="mat-caption">
            <T str="Ex. when a case changes to completed or a contractor completes a case"></T>
          </div>
        </div>

        <div class="df c amvs checkbox-wrapper">
          <mat-checkbox
            name="statusChangeFinished"
            [(ngModel)]="profile.UserPreferencesUnread.statusChangeFinished">
            <T str="Status changed to Finished"></T>
          </mat-checkbox>
          <div class="mat-caption">
            <T str="Ex. when a case changes to finished or a contractor finishes a case"></T>
          </div>
        </div>

        <div class="df c amvs checkbox-wrapper">
          <mat-checkbox
            name="statusChangeReopened"
            [(ngModel)]="profile.UserPreferencesUnread.statusChangeReopened">
            <T str="Status changed to Open"></T>
          </mat-checkbox>
          <div class="mat-caption">
            <T str="Ex. when a case changes from completed to open"></T>
          </div>
        </div>

        <div class="df c amvs checkbox-wrapper">
          <mat-checkbox
            name="caseChanged"
            [(ngModel)]="profile.UserPreferencesUnread.caseChanged">
            <T str="Case changed"></T>
          </mat-checkbox>
          <div class="mat-caption">
            <T str="Ex. when any value/attribute of a case changes"></T>
          </div>
        </div>
      </div>

      <mat-divider></mat-divider>

      <div class="ams display-grid-cards">
        <div class="df c amvs checkbox-wrapper">
          <mat-checkbox
            name="digestHourly"
            [(ngModel)]="profile.digestHourly">
            <T str="Hourly Digest"></T>
          </mat-checkbox>
          <div class="mat-caption">
            <T
              str="We will send a hourly digest of all the case activities that lead to unread cases for the last hour"></T>
          </div>
        </div>

        <div class="df c amvs checkbox-wrapper">
          <mat-checkbox
            name="digestDaily"
            [(ngModel)]="profile.digestDaily">
            <T str="Daily Digest"></T>
          </mat-checkbox>
          <div class="mat-caption">
            <T
              str="We will send a daily digest of all the case activities that lead to unread cases for the previous 24 hours"></T>
          </div>
        </div>

        <div class="df c amvs checkbox-wrapper">
          <mat-checkbox
            name="pushNotifications"
            [(ngModel)]="profile.pushNotifications">
            <T str="Push Notifications"></T>
          </mat-checkbox>
          <div class="mat-caption">
            <T str="Should we use push notifications to send you unreads?"></T>
          </div>
        </div>
      </div>
    </apex-box>

    <apex-box
      class="am"
      *ngIf="!hideSettings">
      <T
        title
        str="Settings for APEX"></T>

      <T
        description
        str="Personalize how APEX looks and feels"></T>

      <div class="ams display-grid-cards">
        <div class="df c amvs checkbox-wrapper">
          <mat-checkbox
            name="showTextOnButtons"
            [(ngModel)]="profile.showTextOnButtons">
            <T str="Show tooltips as text for (some) icon buttons"></T>
          </mat-checkbox>
          <div class="mat-caption">
            <T str="Ex. case actions will show icon and text underneath"></T>
          </div>
        </div>

        <div class="df c amvs checkbox-wrapper">
          <mat-checkbox
            name="useExtendedFileViewer"
            [(ngModel)]="profile.useExtendedFileViewer">
            <T str="Use extended file viewer for files"></T>
          </mat-checkbox>
          <div class="mat-caption">
            <T str="You will be able to view more files in APEX, ex: Excel/Word"></T>
          </div>
          <div class="mat-caption">
            <T
              [sanitize]="true"
              str="Read a bit more about the details of the viewer
                   <a target='_blank'
                   href='https://help.apexapp.io/utvidet-filvisning'>
                   here
                   </a>.">
            </T>
          </div>
        </div>

        <div class="df c amvs checkbox-wrapper">
          <mat-form-field>
            <mat-label>
              <T str="Startpage"></T>
            </mat-label>
            <input
              readonly
              matInput
              [value]="startpageUrl" />

            <button
              matIconSuffix
              color="warn"
              mat-icon-button
              type="button"
              [disabled]="!hasStartpage"
              [matTooltip]="'Clear startpage' | translate"
              (click)="clearStartpage()">
              <mat-icon class="amrs">delete</mat-icon>
            </button>
            <mat-hint>
              <T str="The startpage is the first thing you go to when starting up APEX."></T>
            </mat-hint>
          </mat-form-field>
        </div>
      </div>
    </apex-box>

    <div class="df f1 jfe afe am">
      <button
        mat-raised-button
        color="primary"
        [disabled]="userForm.invalid || !userForm.dirty">
        <T str="Save"></T>
      </button>
    </div>
  </form>

  <form
    (submit)="changePassword()"
    #passwordForm="ngForm">
    <apex-box class="am">
      <T
        title
        str="Change password"></T>

      <T
        description
        str="Change the password for your account"></T>

      <div class="df c">
        <div class="ams">
          <T str="Please fill in your new password twice to change your password"></T>
        </div>

        <div class="df jsb p-c">
          <mat-form-field class="f1 w amhs p-amb">
            <mat-label>
              <T str="New password"></T>
            </mat-label>
            <input
              matInput
              #npInput="ngModel"
              required
              type="password"
              name="newPassword"
              apexValidatePassword
              [(ngModel)]="newPassword" />
            <mat-hint>
              <T str="Your new password"></T>
            </mat-hint>
            <mat-error *ngIf="npInput.errors?.required">
              <T str="This is required"></T>
            </mat-error>
            <mat-error *ngIf="npInput.errors?.password">
              {{ npInput.errors?.password }}
            </mat-error>
          </mat-form-field>

          <!-- adding extra margin-bottom for required hint  -->
          <div class="p-amb"></div>
          <mat-form-field class="f1 amhs">
            <mat-label>
              <T str="Verify new password"></T>
            </mat-label>
            <input
              matInput
              #vpInput="ngModel"
              required
              type="password"
              name="verifyPassword"
              apexValidatePassword
              [apexValidateEqual]="newPassword"
              [(ngModel)]="verifyPassword" />
            <mat-hint>
              <T str="Your new password for verification"></T>
            </mat-hint>
            <mat-error *ngIf="vpInput.errors?.required">
              <T str="This is required"></T>
            </mat-error>
            <mat-error *ngIf="vpInput.errors?.password">
              {{ vpInput.errors?.password }}
            </mat-error>
            <mat-error *ngIf="vpInput.errors?.equal">
              {{ vpInput.errors?.equal }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="df jfe ams p-apt">
        <button
          type="submit"
          mat-raised-button
          [disabled]="!passwordForm.touched || passwordForm.invalid">
          <T str="Change password"></T>
        </button>
      </div>
    </apex-box>
  </form>

  <section class="am">
    <apex-avatars
      class="f1"
      [userId]="profile.id"></apex-avatars>
  </section>
</ng-container>
